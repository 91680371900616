// **  Initial State
const initialState = []
  
  const dropdownDataReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'UPDATE_DROPDOWN_DATA':
        console.log("from reducer", action.data)
        return action.data
    //   case 'LOGOUT':
    //     const obj = { ...action }
    //     delete obj.type
    //     return { ...state, userData: {}, ...obj }
      default:
        return state
    }
  }
  
  export default dropdownDataReducer
  